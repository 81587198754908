//
// forms.scss
//

.invalid-feedback {
    margin-top: -8px;
    margin-bottom: 4px;
  }
  
  input.form-control.is-invalid,
  select.form-select.is-invalid {
    border-bottom: solid 1px #fa5c7c !important;
    border-bottom-color: #fa5c7c !important;
  }
  
  .form-control,
  .form-select {
    padding: 0.15rem 0.6rem;
    margin-bottom: 0.55rem !important;
    width: 100%;
  }
  
  .form-checkbox-list {
    input {
      width: 20px;
      height: 20px;
      border-radius: 6px !important;
    }
  }
  
  .form-checkbox-reverse {
    display: flex;
    flex-direction: row-reverse;
    gap: 30px;
    padding-left: 0;
  }
  
  // Form-control light
  .form-control-light {
    background-color: #{map-get($grays, '100')} !important;
    border-color: #{map-get($grays, '100')} !important;
  }
  
  // Form elements (Color and Range)
  input.form-control[type='color'],
  input.form-control[type='range'] {
    min-height: 39px;
  }
  
  input.form-control.is-invalid[type="date"]::-webkit-inner-spin-button,
  input.form-control.is-invalid[type="date"]::-webkit-calendar-picker-indicator {
      //display: none;
      //-webkit-appearance: none;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23fa5c7c" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  }
  
  input.form-control.is-invalid[type="date"] {
    background-image: none;
  }
  
  // Custom select
  .custom-select.is-invalid,
  .form-control.is-invalid,
  .custom-select:invalid,
  .form-control:invalid {
    padding: 0.15rem 0.6rem;
    width: 100%;
    margin-bottom: 0.55rem !important;
  }
  .custom-select.is-valid,
  .form-control.is-valid,
  .custom-select:valid,
  .form-control:valid {
    &:focus {
      box-shadow: none !important;
    }
  }
  
  select.form-control {
    &:not([size]):not([multiple]) {
      height: $input-height;
    }
  }
  
  select.form-control-sm {
    &:not([size]):not([multiple]) {
      height: $input-height-sm;
    }
  }
  
  // Show Password
  .password-eye {
    &:before {
      font-family: 'Material Design Icons';
      content: '\F06D0';
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      vertical-align: middle;
      line-height: 1.2;
      font-size: 16px;
    }
  }
  
  .show-password {
    .password-eye {
      &:before {
        content: '\F06D1';
      }
    }
  }
  
  // Dark mode
  body[data-layout-color='dark'] {
    .form-control-light {
      background-color: #{map-get($dark-grays, '100')} !important;
      border-color: #{map-get($dark-grays, '100')} !important;
    }
  }
  