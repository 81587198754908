.message-page {

  .message-card {
    padding: 0.75rem;
    font-size: 14px;
    border-radius: 8px;

    .card-body .card-widgets {
      padding: 7px;
    }

    .card-title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 12px;
      color: $gray-700;
      cursor: pointer;

      p {
        margin-top: 0.375rem;
        margin-bottom: 0;
        font-size: 14px;
        color: $gray-800;
      }
    }
  }

  .message-body {
    padding-top: 15px;
    margin-top: 8px;
    line-height: 1.3;
    border-top: solid 1px $gray-300;

    p:last-child {
      margin: 0;
    }
  }
}