//
// topbar.scss
//

.modal-title {
    color: #aaaaaa;
    font-size: 0.8rem;
    font-weight: 400;
  }
  
  .form-label {
    margin-bottom: 0;
  }
  
  .navbar-custom {
    padding: 0 $grid-gutter-width;
    background-color: $bg-topbar;
    box-shadow: $box-shadow;
    // min-height: $topbar-height;
    position: fixed;
    left: $leftbar-width;
    top: 0;
    right: 0;
    z-index: 1001;
  
    .no-search-bar {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      height: 50px;
    }
  
    .logged-user-section {
      display: flex;
      justify-content: flex-end;
      padding: 7px 0;
      font-size: 14px;
      line-height: 1;
    }
  
    .topbar-inner-container {
      display: flex;
      flex-direction: row;
  
      .search-section {
        display: flex;
        padding: 0 10px 15px 10px;
        width: 100%;
      }
    }
  
    .topbar-left {
      background-color: $bg-topbar;
      height: $topbar-height;
      position: fixed;
      z-index: 1;
      width: $leftbar-width;
      text-align: center;
      top: 0;
      left: 0;
      .logo {
        line-height: $topbar-height;
        i {
          display: none;
        }
      }
    }
    .topbar-menu {
      position: relative;
      z-index: 1;
  
      li {
        float: left;
        max-height: $topbar-height;
  
        .show {
          &.nav-link {
            color: #{map-get($grays, '700')};
          }
        }
      }
      .nav-link {
        padding: 0;
        color: #{map-get($grays, '600')};
        min-width: 32px;
        display: block;
        text-align: center;
        margin: 0 10px;
        position: relative;
      }
    }
  }
  
  .topbar-dropdown {
    .nav-link {
      line-height: $topbar-height;
    }
  }
  
  /* Search */
  
  .app-search {
    width: 100%;
  
    .search-control {
      border-radius: 5px;
      border: none;
      height: $input-height;
      background-color: $bg-topbar-search;
      box-shadow: none;
      overflow: hidden;
    }
    .search-container {
      display: flex;
      padding: 0 0 0 55px;
      // height: 38px;
  
      input {
        background: 0px center;
        width: 100%;
        height: 38px;
        border: 0;
        outline: 0;
      }
    }
    .search-sub-control {
      margin-top: 1px;
      background-color: #fff;
      position: absolute;
      left: 60px;
      right: $grid-gutter-width;
      padding: 12px 36px 24px 24px;
      border: solid 1px $gray-300;
      border-radius: 5px;
      box-shadow: 0px 4px 4px 0px $gray-300;
  
      .row > div {
        margin-top: 12px;
      }
  
      .form-label {
        color: #aaaaaa;
        font-size: 0.8rem;
        font-weight: 400;
      }
  
      button {
        height: 24px;
        width: 24px;
        font-size: 14px;
        font-weight: bolder;
        right: 12px;
        top: 10px;
        padding: 0;
      }
    }
    span.search-icon {
      z-index: 9;
      font-size: 20px;
      line-height: 38px;
      margin-left: 20px;
      position: absolute;
      float: left;
    }
    button {
      border-radius: 5px;
      position: absolute;
      // top: calc(#{$topbar-height - 38px} / 2);
      right: $grid-gutter-width;
    }
  }
  
  /* Notification */
  .notification-list {
    margin-left: 0;
  
    .noti-title {
      background-color: transparent;
      padding: 15px 20px;
    }
  
    .noti-icon {
      font-size: 22px;
      vertical-align: middle;
      line-height: $topbar-height;
    }
  
    .noti-icon-badge {
      display: inline-block;
      position: absolute;
      top: 22px;
      right: 6px;
      border-radius: 50%;
      height: 7px;
      width: 7px;
      background-color: $danger;
    }
  
    .notify-item {
      padding: 10px 20px;
      &.unread-noti {
        background-color: #{tint-color(map-get($grays, '100'), 15%)};
      }
      &.read-noti {
        background-color: transparent;
        border: #{$border-width} solid #{map-get($grays, '200')};
      }
      .card-body {
        padding: 14px;
        .noti-close-btn {
          position: absolute;
          top: 3px;
          right: 5px;
        }
        .noti-item-title {
          margin: 0 0 2px;
        }
        .noti-item-title,
        .noti-item-subtitle {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
  
      .notify-icon {
        float: left;
        height: 36px;
        width: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 50%;
        color: $white;
      }
  
      .notify-details {
        margin-bottom: 0;
        overflow: hidden;
        margin-left: 45px;
  
        b {
          font-weight: 500;
        }
        small {
          display: block;
        }
      }
  
      .user-msg {
        margin-left: 45px;
        white-space: normal;
        line-height: 16px;
      }
    }
    .topbar-dropdown-menu {
      .notify-item {
        padding: 7px 20px;
      }
    }
  }
  
  .profile-dropdown {
    min-width: 170px;
  
    i,
    span {
      vertical-align: middle;
    }
  }
  
  .nav-user {
    padding: calc(#{$topbar-height - 39px} * 0.5) 20px calc(#{$topbar-height - 39px} * 0.5) 57px !important;
    text-align: left !important;
    position: relative;
    background-color: #{lighten(map-get($grays, '100'), 2.5%)};
    border: #{($border-width) solid map-get($grays, '100')};
    border-width: 0 1px;
    min-height: $topbar-height;
  
    .account-user-avatar {
      position: absolute;
      top: calc(#{$topbar-height - 32px} * 0.5);
      left: 15px;
  
      img {
        height: 32px;
        width: 32px;
      }
    }
    .account-position {
      display: block;
      font-size: 12px;
      margin-top: -3px;
    }
    .account-user-name {
      display: block;
      font-weight: $font-weight-semibold;
    }
  }
  
  .button-menu-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #{map-get($grays, '900')};
    height: 38px;
    line-height: $topbar-height;
    width: 50px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
    z-index: 1;
  
    &.disable-btn {
      display: none;
    }
  
    @include media-breakpoint-down(md) {
      margin: 0 !important;
    }
  }
  
  [data-keep-enlarged='true'] {
    .navbar-custom {
      padding-left: 0;
    }
  
    .button-menu-mobile {
      margin: 0;
  
      &.disable-btn {
        display: inline-block;
      }
    }
  }
  
  @media (max-width: 576px) {
    .navbar-custom {
      .topbar-menu {
        position: initial;
      }
  
      .dropdown {
        position: static;
  
        .dropdown-menu {
          left: 10px !important;
          right: 10px !important;
        }
      }
    }
  }
  
  @media (max-width: 1200px) {
    .navbar-custom {
      right: 0;
    }
  }
  
  @include media-breakpoint-down(lg) {
    .nav-user {
      padding: 17px 5px 17px 57px !important;
  
      .account-position,
      .account-user-name {
        display: none;
      }
    }
  }
  
  @include media-breakpoint-down(md) {
    .navbar-custom {
      left: 0 !important;
      padding-left: 0;
    }
    .button-menu-mobile {
      &.disable-btn {
        display: inline-block;
      }
    }
  }
  
  @media (max-width: 375px) {
    .navbar-custom {
      .topbar-menu {
        .nav-link {
          margin: 0 5px;
        }
      }
  
      &.topnav-navbar {
        .container-fluid {
          padding-right: 12px;
          padding-left: 12px;
        }
        .navbar-toggle {
          margin: 27px 3px;
        }
        .button-menu-mobile {
          width: auto;
        }
      }
    }
  }
  
  // Scrollable Layout
  
  @include media-breakpoint-up(lg) {
    body[data-leftbar-compact-mode='scrollable']:not([data-layout-mode='boxed']):not([data-layout='topnav']) {
      .navbar-custom {
        position: absolute;
      }
    }
  }
  
  body[data-layout-mode='boxed'] {
    .navbar-custom {
      position: relative;
      left: 0 !important;
      margin: -$topbar-height (-$grid-gutter-width * 0.5) 0;
    }
  
    &[data-layout='topnav'] {
      .navbar-custom {
        margin: 0;
      }
    }
  }
  
  // Dark mode
  body[data-layout-color='dark'] {
    .navbar-custom {
      .topbar-menu {
        li {
          .show {
            &.nav-link {
              color: #{map-get($dark-grays, '700')};
            }
          }
        }
        .nav-link {
          color: #{map-get($dark-grays, '600')};
        }
      }
    }
    .notification-list {
      .notify-item {
        &.unread-noti {
          background-color: #{tint-color(map-get($dark-grays, '300'), 1%)};
        }
        &.read-noti {
          border: #{$border-width} solid #{map-get($dark-grays, '300')};
        }
      }
    }
  
    .button-menu-mobile {
      color: #{map-get($dark-grays, '900')};
    }
  
    .nav-user {
      background-color: #{lighten(map-get($dark-grays, '100'), 2.5%)};
      border: #{($border-width) solid map-get($dark-grays, '100')};
    }
  }
  