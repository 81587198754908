.loans-page {
  margin-top: 12px;
  color: #000000;

  .loans-filter {
    padding: 6px 0 12px 0;    
    background-color: #f2f2f2;
    font-size: 12px;    
    margin: 10px 0px 0px 0px;
    
    p.loans-filter-title {
      width: auto;
      padding-right: 4px;
      padding-top: 6px;
      float: left;
    }

    div.col-9 {
      padding: 0;
    }

    button {
      margin: 6px 0 0 0;
      padding: 0 4px;
      font-size: 12px;  
      color: #000000;  

      span.loans-filter-count {
        border-radius: 4px;
        padding: 2px 8px;
        margin-left: 3px;
        color: #ffffff;
      }
    }
  }

  table {
    color: #000000;

    td {
      font-size: 12px;    
      font-family: "Arial", sans-serif;
      padding: 3px 5px;
      .badge {
        font-size: 10px;
        padding: 3px 7px;
      }
    }

    td.td-table-row {
      cursor: pointer;
    }
    
    th {
      padding: 12px 20px 12px 6px;
      font-size: 13px;
    }
    
    //th:nth-child(4), th:nth-child(5) {
    //  background-color: #D7F5DB80 !important;
    //}

    tr.sub-row td:last-child {
      width: 50%;
    }

    tbody tr:not(.sub-row):hover {
      background-color: $gray-200;
    }
  }
  
  .react-table .sortable:before {
    top: 16px;
  }
  .react-table .sortable:after {
    top: 22px;
  }
  .react-table th, .react-table td {
    white-space: normal !important;
  }

}