.profile-page {

  padding-top: 24px;

  h6 {
    margin-top: 36px;
    margin-bottom: 0;
  }
  
  .new_secondary_email_row.row>div {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    .new_secondary_email_row {
      margin-top: 27px;
    }
  }

  button.new_secondary_email_btn {
    float: none;
    margin-top: 28px;
    width: 100%;
    border-radius: 5px;
    background-color: #007248;
    border-color: #007248;
  }

  .secondary_email_select {
    height: 130px;
  }
  
  .delete_secondary_email {
    margin-left: 0;
    margin-top: 15px;

    button {
      border-radius: 5px;
      float: none;
      width: auto;
    }
  }
  

  .profiles-item {
    display: flex;
    margin: 0.75rem 0 !important;

    &>div {
      margin-right: 30px;
    }
  }
}